import { createObservableDataAction, IAction, ICreateActionContext } from '@msdyn365-commerce/core';
import { retailAction } from '@msdyn365-commerce/retail-proxy';
import { createGetAllCSRClassificationIconTableInput } from '../DataActionExtension.g';
import { IK3CSRClassificationIconTable } from '../DataServiceEntities.g';

export default createObservableDataAction({
    action: <IAction<IK3CSRClassificationIconTable[]>>retailAction,
    input: (context: ICreateActionContext) => {
        return createGetAllCSRClassificationIconTableInput({ Paging: { Top: 250 } });
    }
});
