import { createObservableDataAction, IAction, ICreateActionContext } from '@msdyn365-commerce/core';
import { retailAction } from '@msdyn365-commerce/retail-proxy';
import { createGetAllCompositionTypeTableInput } from '../DataActionExtension.g';
import { IK3CompositionTypeTable } from '../DataServiceEntities.g';

export default createObservableDataAction({
    action: <IAction<IK3CompositionTypeTable[]>>retailAction,
    input: (context: ICreateActionContext) => {
        return createGetAllCompositionTypeTableInput({ Paging: { Top: 250 } });
    }
});
